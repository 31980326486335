//const client = require("twilio");
import React ,{Component,useEffect,useState,useContext}from 'react';
import PropTypes from 'prop-types';
import './OnboardingMoodPicker.css';
import {allMoods,moodPickerContent} from '../../utils/constants';
import {sendTwilioMessage, setMyMood} from '../../utils/api';
import TitleHeader from '../TitleHeader/TitleHeader'
import { Button, Space } from 'antd-mobile';
import { Link, useLoaderData, useNavigation } from 'react-router-dom';
import Login from '../Login/Login';
import SignUp from '../SignUp/SignUp';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../AppContext'
//const client = require('twilio')(accountSid, authToken);


function OnboardingMoodPicker(){
  const { appUser } = useContext(AppContext);
  const { appUserMood, setAppUserMood  } = useContext(AppContext);
let [currentMood,setMood] = useState('')
const handleLogout = () => {
  firebase.auth().signOut();
  // User has logged out
};

let navigate = useNavigate()
const user = firebase.auth().currentUser;
const selectMood=(e)=>{
    var mood = e.currentTarget.getAttribute("data-value");
    var moodMessage = e.currentTarget.getAttribute("data-message");
    var moodLabel = document.getElementById('moodlabel')
    const message = "Curt"+ moodMessage
    moodLabel.hidden = false
    
   
    setMood(mood)
    //sendTwilioMessage('+14159997117',message,'general');
    setMyMood(mood)
    setAppUserMood(mood)
    navigate('/cares')
    
 
  }
const welcomeTitle = "Hi " + appUser.displayName + ", Welcome to TakeCare"
const setCurrentMood=()=>{
  if (!appUserMood){

  } else {
    
    return(
<div>
  Your current mood : {appUserMood}
</div>


    )
  }
}

return( 

<>

<div>
      
      {user ? (
        
        <div className="OnboardingMoodPicker">
        
        <TitleHeader title ={welcomeTitle} subtitle={moodPickerContent.subtitle}/>
        <div>{user.identifier}</div>
        <div className='moodholder'>
        {
        allMoods.map(mood =>(
           
           <div className='mooditem' key={mood.mood} >
            <Button className={mood.style} data-value={mood.mood} data-message={mood.moodMessage} onClick={selectMood} >
           {mood.mood}
           </Button>
           <div className='mood-caption'>{mood.message}</div>
           </div>
     
        ))
       }
     
     
     <div id='moodlabel' hidden='true' style={{fontSize:'large'}}>
       Your current mood : {currentMood}
     </div>
     
     {/* <Link to="/circles" className='mood-letsgetstarted'>Let's Get Started</Link> */}
       
     </div>
     
     
     
       </div>



      ) : (
        <>
          <Login />
        
        </>
      )}
    </div>


  
  
 
  
  
  </>
  
  
  )
};

OnboardingMoodPicker.propTypes = {};

OnboardingMoodPicker.defaultProps = {};

export default OnboardingMoodPicker;
