import React, { createContext, useState } from 'react';

// Create a new context
const AppContext = createContext();

// Create a custom provider component for the context
const AppProvider = ({ children }) => {
    const [appUser, setAppUser ] = useState('');
    const [appUserMood, setAppUserMood] = useState('');
    const [appCurrentReciever, setAppCurrentReciever] = useState('');
    

  return (
    <AppContext.Provider value={{  appUser, setAppUser,appUserMood, setAppUserMood,appCurrentReciever, setAppCurrentReciever }}>
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
