import React,{useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import './CircleItem.css';
import { List,Tag,Space, Button } from 'antd-mobile';
import { database,dbset,dbref,dbOnValue,getCurrentUserUid,dbpush, dbChild } from '../../utils/dataobject'
import { createInitials } from '../../utils/api';
import Connect from '../Connect/Connect';
import UnreadMessages from '../UnreadMessages/UnreadMessages';
import Connected from '../Connected/Connected';

const colorReturn=(color,show)=>{
  if (show){
  switch (color) {
    case "Green": 
      return <div className='colorbubble-green'> 
      </div>
      break;
    case "Dark":
      return <div className='colorbubble-black'> 
      </div>
      break;
    case "Purple":
      return <div className='colorbubble-purple'> 
      </div>
      
      break;
    case "Yellow":
      return <div className='colorbubble-yellow'> 
      </div>
      
      break;
  
    default:
      break;
  }
  }
}

const checkinReturn=(color,show)=>{
if (show){
  switch (color) {
    case "Dark":
      return <div> <Tag color='black'>Check In?</Tag></div>
      break;
    case "Purple":
      return <div> <Tag color='purple'>Check In?</Tag></div>
      break;
    default:
      break;
  }
}
}
export const getUnreadMessageCountBetweenUsers = (user1Id) => {
  return new Promise((resolve,reject)=>{
  getCurrentUserUid()
  .then((uid) => {

  const messagesRef = dbref(database, 'messages');
  const user2Id = uid
  let unreadCount = 0;

  // Attach a listener to the 'messages' node
  dbOnValue(messagesRef, (snapshot) => {
    // Get the messages data from the snapshot
    const messagesData = snapshot.val();

    // Check if there are any messages
    if (messagesData) {
      // Loop through each message
      Object.entries(messagesData).forEach(([messageId, message]) => {
        // Check if the message is between the two users and is unread
        if (
          (message.receiver === user1Id && message.sender === user2Id && !message.read) ||
          (message.receiver === user2Id && message.sender === user1Id && !message.read)
        ) {
          // Increment the unread count
          unreadCount++;
        }
      });
    }

    // Log the unread count (you can do anything with this value)
    console.log(`Unread messages between users ${user1Id} and ${user2Id}: ${unreadCount}`);
    
  }, (error) => {
    console.error('Error retrieving messages:', error);
  });
  resolve(unreadCount) 
})
  })
};




const showLastMessage=(show,value)=>{
if (show && value > 0){
  return(
    <>
    {new Date(value).toLocaleString()}
    
    </>
  )
  } else {
    return(
      <>
      2023
      
      </>
    )
  }
}








const CircleItem = (props) => {

  const [users, setUsers] = useState([]);
  const [userID, setUserID] = useState("");
  const [unreadCount, setUnreadCount] = useState(0);


  
  return(
  <List.Item key={props.index} onClick={props.click} data-value={props.uid}>
  <div className='circle-item'>
    <div className='circle-holder'>
    <div className='initials'> 
      <div className='initials-name'>  
         {createInitials(props.person.firstname ,props.person.lastname)}
     </div>
   </div>
   {
    colorReturn(props.person.mood,props.showcolor)
   }
   <div>
  <div className='circle-name'>
  <> {props.person.firstname} {props.person.lastname} </>
  </div>
  <div>
    <div className='circle-subtitle'>
  {showLastMessage(props.showlastmessage,props.person.lastmessage)}
    </div>  
   
   
    </div>
   
    </div>
  {
      checkinReturn(props.person.mood,props.showcheckin)
  }
 {/* {
     unreadMessages(props.showunread,props.uid)
 } */}

<UnreadMessages person={props.person} show={props.showunreadmessages} />
{props.showconnect ? <Connected loggedinuser={props.loggedinuser} showconnect={props.showconnect} uid={props.uid}
person={props.person}
/>: ''}

  </div>

  </div>
 
 </List.Item> 
  )
};

CircleItem.propTypes = {};

CircleItem.defaultProps = {};

export default CircleItem;
