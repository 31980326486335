// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase,onValue,ref,set,push, child } from "firebase/database";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import { getAuth, onAuthStateChanged } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

export const firebaseConfig = {
  apiKey: "AIzaSyDZ6IZucanE9ySHY3n8IK5ptzYxdz9g_CQ",
  authDomain: "takecare-f1da3.firebaseapp.com",
  projectId: "takecare-f1da3",
  databaseURL:"https://takecare-f1da3-default-rtdb.firebaseio.com/",
  storageBucket: "takecare-f1da3.appspot.com",
  messagingSenderId: "246473179975",
  appId: "1:246473179975:web:361136b07577ca0817c262",
  measurementId: "G-773SBH6TVQ"
};



// Initialize Firebase

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const database = getDatabase(app);
export const dbset = set
export const dbref = ref
export const dbpush = push
export const dbOnValue = onValue
export const dbChild = child
firebase.initializeApp(firebaseConfig)



export const getCurrentUserUid = () => {
  return new Promise((resolve, reject) => {
    // onAuthStateChanged listens for changes in the authentication state
    // It will be triggered when the user signs in or signs out
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // If the user is signed in, 'user' will contain user information, including the UID
        const uid = user.uid;
        resolve(user.uid);

       // return user
      } else {
        // If the user is not signed in, 'user' will be null
        // You may want to handle this case based on your application's requirements
        reject(new Error("User is not signed in."));
      }
    });
  });
};

export default firebase