import React, { useState,useContext } from 'react';
import './Login.css'
// import firebase from 'firebase/compat/app';
// import 'firebase/compat/auth';
import firebase from '../../utils/firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../AppContext'
const Login = () => {


  const { appUser, setAppUser  } = useContext(AppContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate()
  

  const handleLogin = async () => {
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
      setAppUser(firebase.auth().currentUser)
      console.log(firebase.auth().currentUser.email)
      console.log(firebase.auth().currentUser)
      
      navigate('/mood')
      // User has successfully logged in
     
    } catch (error) {
      setError(error.message);
    }
  };

 

  return (
    <div>
      <h2>Login</h2>
      <table>
        <tr>
          <td>
 <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />

          </td>
        </tr>
        <tr>
          <td>
 <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />

          </td>
        </tr>

        <tr>
        <td>
{error && <p style={{ color: 'red' }}>{error}</p>}
      <button onClick={handleLogin}>Login</button>
      <p>Don't have an account? <a href="/signup">Sign Up</a></p>
        </td>


        </tr>
      </table>
     
     
      
    </div>
  );
};

export default Login;
