import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './UnreadMessages.css';
import { database, dbref, dbOnValue, getCurrentUserUid } from '../../utils/dataobject';

const UnreadMessages = (props) => {
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    // Fetch user ID and unread message count
    const fetchUserData = async () => {
      try {
        if (!props.person || !props.person.uid) return; // Handle the case when person prop is not available yet

        // Fetch unread message count here and set it to state
        const count = await getUnreadMessageCountBetweenUsers(props.person.uid);
        setUnreadCount(count);
      } catch (error) {
        console.error(error);
      }
    };

    
      fetchUserData();
    
   
  }, [props.person]);

  const getUnreadMessageCountBetweenUsers = async (user1Id) => {
    try {
      const uid = await getCurrentUserUid();
      const messagesRef = dbref(database, 'messages');
      const user2Id = uid;
      let unreadCount = 0;

      // Attach a listener to the 'messages' node
      dbOnValue(messagesRef, (snapshot) => {
        // Get the messages data from the snapshot
        const messagesData = snapshot.val();

        // Check if there are any messages
        if (messagesData) {
          // Loop through each message
          Object.entries(messagesData).forEach(([messageId, message]) => {
            // Check if the message is between the two users and is unread
            if (
              (message.receiver === user1Id && message.sender === user2Id && !message.read) ||
              (message.receiver === user2Id && message.sender === user1Id && !message.read)
            ) {
              // Increment the unread count
              unreadCount++;
            }
          });
        }

        // Log the unread count (you can do anything with this value)
        console.log(`Unread messages between users ${user1Id} and ${user2Id}: ${unreadCount}`);
        setUnreadCount(unreadCount); // Set the unread count state
      }, (error) => {
        console.error('Error retrieving messages:', error);
      });

      return unreadCount;
    } catch (error) {
      console.error('Error fetching data:', error);
      return 0;
    }
  };

  return (
    <div className="UnreadMessages">
      
      {unreadCount > 0 && (
       <div className='color-bubble-orange'>
{unreadCount}
       </div>
      )}
      
    </div>
  );
};

UnreadMessages.propTypes = {
  person: PropTypes.object,
};

UnreadMessages.defaultProps = {};

export default UnreadMessages;
