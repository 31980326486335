import { database,dbset,dbref,dbOnValue,getCurrentUserUid,dbpush, dbChild } from './dataobject'
import axios from 'axios';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import {twilioConfig,helpMessage} from './constants'



export const markMessagesAsRead = (senderId, receiverId) => {
  const messagesRef = dbref(database, 'messages');

  // Fetch all messages
  dbOnValue(messagesRef, (snapshot) => {
    const messagesData = snapshot.val();

    if (messagesData) {
      Object.entries(messagesData).forEach(([messageId, message]) => {
        // Check if the message is between the specified sender and receiver
        const isBetweenUsers =
          (message.sender === senderId && message.receiver === receiverId) ||
          (message.sender === receiverId && message.receiver === senderId);

        if (isBetweenUsers && !message.read) {
          // Update the "read" property to mark the message as read
          const messageRef = dbChild(messagesRef, messageId);
          dbset(messageRef, { ...message, read: true });
        }
      });
    }
  }, (error) => {
    console.error('Error retrieving messages:', error);
  });
};

export const getUnreadMessageCountBetweenUsers = (user1Id) => {
  getCurrentUserUid()
  .then((uid) => {

  const messagesRef = dbref(database, 'messages');
  const user2Id = uid
  let unreadCount = 0;

  // Attach a listener to the 'messages' node
  dbOnValue(messagesRef, (snapshot) => {
    // Get the messages data from the snapshot
    const messagesData = snapshot.val();

    // Check if there are any messages
    if (messagesData) {
      // Loop through each message
      Object.entries(messagesData).forEach(([messageId, message]) => {
        // Check if the message is between the two users and is unread
        if (
          (message.receiver === user1Id && message.sender === user2Id && !message.read) ||
          (message.receiver === user2Id && message.sender === user1Id && !message.read)
        ) {
          // Increment the unread count
          unreadCount++;
        }
      });
    }

    // Log the unread count (you can do anything with this value)
    console.log(`Unread messages between users ${user1Id} and ${user2Id}: ${unreadCount}`);
    
  }, (error) => {
    console.error('Error retrieving messages:', error);
  });
  return unreadCount
})
};





export const sendTwilioMessage = async (phoneNumber,message,type) =>{
   
   switch (type) {
    case 'welcome':
        try {
            const response = await axios.post(twilioConfig.welcomeMessageURL, {
              phoneNumber,
              message
            });
            //alert(response.data.status);
          } catch (error) {
            console.error(error);
          }
        break;
    case 'general':
        try {
            const response = await axios.post(twilioConfig.sendMessageURL, {
              phoneNumber,
              message
            });
            //alert(response.data.status);
          } catch (error) {
            console.error(error);
          }
        
        break;
   
    default:
        break;
   }
   
   
  
}
export const currentUserID =()=>{
    //TODO capture data then lookup
    getCurrentUserUid()
    .then((uid) => {
       return uid
    })
  
    
.catch((error) => {
        console.error("Error:", error);
        // Handle the error here
      });
}

export const isUserConnectedToMe = (connectionUID)=>{
  getCurrentUserUid()
  .then((uid) => {
    const connectorConnectionsPath = `users/` + uid + `/connections/` + connectionUID 
    const datapath =  dbref(database,connectorConnectionsPath)
     dbOnValue(datapath,(snapshot) =>{
         const data = snapshot.val()
         if (!data) {
           return true
         } 
         else {
           return false
         }
     })
 
  }
)}


export const addToMyCircle=(connectionUID)=>{
    //TODO capture data then lookup
    
    getCurrentUserUid()
    .then((uid) => {
    const connectorConnectionsPath = `users/` + uid + `/connections/` + connectionUID 
    const recieverConnectionsPath = `users/` + connectionUID + `/connections/` + uid
    const recieverUserDetails = getUserInfo(connectionUID)
    const connectorUserDetails = getUserInfo(uid)
   
    const connectorMessage = connectorUserDetails['firstname'] + ", your new connection " + recieverUserDetails['firstname'] + " just sent you a message on TakeCare https://takecare-f1da3.web.app/  "

    const recieverMessage = recieverUserDetails['firstname'] + ", your new connection " + connectorUserDetails['firstname']   + " just sent you a message on TakeCare https://takecare-f1da3.web.app/"
   
    sendIntroMessage(uid,connectionUID,connectorUserDetails['firstname'],recieverUserDetails['firstname'])
   // console( connectorMessage)
    dbset(dbref(database,connectorConnectionsPath),recieverUserDetails);
    dbset(dbref(database,recieverConnectionsPath),connectorUserDetails);
    sendTwilioMessage(recieverUserDetails['phonenumber'],recieverMessage,'general')
    sendTwilioMessage(connectorUserDetails['phonenumber'],connectorMessage,'general')
   
      })
.catch((error) => {
        console.error("Error:", error);
        // Handle the error here
      });


   
}
// export const allUsers=()=>{
//         const datapath = `users` 
//         const currentUsers = dbref(database,datapath)
//         const users =[]
//         dbOnValue(currentUsers,(snapshot) => {
//            console.log( "DSS" + snapshot.val())
//         Object.entries(snapshot.val()).map((key,val) =>{
//             //console.log(key,val)
          
//         })
//         })


//      return users
// }


export const allUsers = () => {
    return new Promise((resolve, reject) => {
      const datapath = 'users';
      const currentUsers = dbref(database, datapath);
      const users = [];
  
      dbOnValue(currentUsers, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          // Convert the object of users into an array of user info objects
          const usersInfo = Object.values(data).map((user) => user.info);
          users.push(...usersInfo);
        }
  
        // Resolve the promise with the users' info
        resolve(users);
      }, (error) => {
        // Reject the promise if there's an error
        reject(error);
      });

      return users
    });
  };




  export const getUserConnections = (uid) => {
    return new Promise((resolve, reject) => {
      const currentUsers = dbref(database, 'users');
  
      dbOnValue(currentUsers, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          // Check if the provided UID exists in the data
          if (data[uid]) {
            const user = data[uid];
            // Check if the user has connections
            if (user.connections) {
              const connections = user.connections;
              resolve(connections);
            } else {
              // If the user has no connections, resolve with an empty object
              resolve({});
            }
          } else {
            // If the provided UID doesn't exist, resolve with null
            resolve(null);
          }
        } else {
          // If data is null or empty, resolve with null
          resolve(null);
        }
      }, (error) => {
        // Reject the promise if there's an error
        reject(error);
      });
    });
  };
  


  // export const allUsersx = () => {
  //   return new Promise((resolve, reject) => {
  //     const datapath = 'users';
  //     const currentUsers = dbref(database, datapath);
  //     const users = [];
      
  
  //     dbOnValue(currentUsers, (snapshot) => {
  //       const data = snapshot.val();
  //       if (data) {
  //         // Convert the object of users into an array of user info objects
  //         const usersInfo = Object.entries(data).map((user) => user);
  //         usersInfo.map(obj=>{
  //           let userx = {}
  //           userx["uid"] = obj[0]
  //           userx['info'] = obj[1].info

  //           if (obj[1].connections){
  //               userx['connections'] = obj[1].connections
  //           }

  //           users.push(userx);
  //         })
         
          
  //       }
  
  //       // Resolve the promise with the users' info
  //       resolve(users);
  //     }, (error) => {
  //       // Reject the promise if there's an error
  //       reject(error);
  //     });

  //     return users
  //   });
  // };

  // export const allUsersx = (currentUserUid = null) => {
  //   return new Promise((resolve, reject) => {

  //     const datapath = 'users';
  //     const currentUsers = dbref(database, datapath);
  //     const users = [];
  
  //     getCurrentUserUid()
  //     .then((uid) => { 
      
  //     dbOnValue(currentUsers, (snapshot) => {
  //       const data = snapshot.val();
  //       if (data) {
  //         // Convert the object of users into an array of user info objects
  //         const usersInfo = Object.entries(data).map((user) => user);
  //         usersInfo.map((obj) => {
  //           let userx = {};
  //           userx["uid"] = obj[0];
  //           userx['info'] = obj[1].info;
  
  //           if (obj[1].connections) {
              
  //             userx['connections'] = obj[1].connections;
  //           }
  //           users.push(userx);
  //         });
  
  //         // Filter users based on the currentUserUid
  //         const filteredUsers = uid
  //           ? users.filter((user) => user.uid != uid)
  //           : users;
  
  //         resolve(filteredUsers);
  //       } else {
  //         // Resolve with an empty array if there's no data
  //         resolve([]);
  //       }
  //     }, (error) => {
  //       // Reject the promise if there's an error
  //       reject(error);
  //     });
  //   })

  //   });
  // };

  export const allUsersx = (currentUserUid = null) => {
    return new Promise((resolve, reject) => {
      const datapath = 'users';
      const currentUsers = dbref(database, datapath);
      const users = [];
  
      getCurrentUserUid().then((uid) => {
        dbOnValue(currentUsers, (snapshot) => {
          const data = snapshot.val();
          if (data) {
            // Convert the object of users into an array of user info objects
            const usersInfo = Object.entries(data);
            usersInfo.map(([userUid, userValue]) => {
              let userx = {
                uid: userUid,
                info: userValue.info,
                connections: userValue.connections || {},
                latestMessageTimestamp: 0
              };
  
              // Iterate over the connections and find the latestMessageTimestamp
              Object.values(userx.connections).forEach(connection => {
                if (connection.lastMessage > userx.latestMessageTimestamp) {
                  userx.latestMessageTimestamp = connection.lastMessage;
                }
              });
  
              users.push(userx);
            });
  
            // Sort users based on the latestMessageTimestamp in descending order
            users.sort((a, b) => b.latestMessageTimestamp - a.latestMessageTimestamp);
  
            // Filter users based on the currentUserUid
            const filteredUsers = uid ? users.filter((user) => user.uid !== uid) : users;
  
            resolve(filteredUsers);
          } else {
            // Resolve with an empty array if there's no data
            resolve([]);
          }
        }, (error) => {
          // Reject the promise if there's an error
          reject(error);
        });
      });
    });
  };
  
  
  export const allUsersConnections = async (currentUserUid = null) => {
    try {
        const uid = currentUserUid || await getCurrentUserUid();
        
        const datapath = `users/${uid}/connections`;
        const currentUsers = dbref(database, datapath);
        
        return new Promise((resolve, reject) => {
            dbOnValue(currentUsers, async (snapshot) => {
                const data = snapshot.val();
                
                if (!data) {
                    return resolve([]);
                }

                // Use map to transform the connections object into an array of users
                // And add additional properties like mood 
                const usersPromises = Object.values(data).map(async (value) => {
                    return {
                        uid: value.uid,
                        email: value.email,
                        firstname: value.firstname,
                        lastname: value.lastname,
                        initials: value.initials,
                        mood: await getMoodOfUser(value.uid), 
                        phonenumber: value.phonenumber,
                        lastmessage: value.lastMessage || "0"
                    };
                });

                let users = await Promise.all(usersPromises);

                // Sort users based on the lastMessage
                users = users.sort((a, b) => b.lastmessage - a.lastmessage);

                // Filtering based on the uid is unnecessary since we're fetching connections for the current user.
                // Hence, this step can be removed, or the function's purpose should be reconsidered.

                resolve(users);
            }, (error) => {
                reject(error);
            });
        });

    } catch (error) {
        console.error("Failed to fetch user connections:", error);
        throw error;
    }
};

  
  


  
  


  export const getMoodOfUser = (uid) => {
    return new Promise((resolve, reject) => {
        const datapath = `users/${uid}/info/mood`;
        const profileInfo = dbref(database, datapath);
        
        dbOnValue(profileInfo, (profiledata) => {
            if (profiledata.exists()) {
                const mood = profiledata.val();
                console.log("currentMood:", mood);
                resolve(mood);
            } else {
                reject(new Error(`No mood data found for user with UID: ${uid}`));
            }
        }, (error) => {
            console.error("Failed to fetch mood for user:", error);
            reject(error);
        });
    });
};


export const sendIntroMessage = (senderuid,receiveruid, senderName,receiverName) => {

  
  const messageData = {
    "sender": senderuid,
    "receiver": receiveruid,
    "content": 'Hey 👋 how are you doing today?',
    "timestamp": firebase.database.ServerValue.TIMESTAMP,
  };

  const messagesRef = dbref(database, 'messages');
  dbpush(messagesRef, messageData)
    .then(() => {
      console.log('New message inserted successfully!');
    })
    .catch((error) => {
      console.error('Error inserting new message:', error);
    });




};

export const setMyMood= (mood)=> {
 // 

  getCurrentUserUid()
    .then((uid) => {
  const currentUserData = getUserInfo(uid)
  const datapath = `users/` + uid + `/info/mood`
  dbset(dbref(database,datapath),mood);  
      switch (mood) {
        case "Green": 
          
          break;
        case "Dark":
          sendMessagesToAllConnections(uid,'dark')
          break;
        case "Purple":
          sendMessagesToAllConnections(uid,'purple')
          break;
        case "Yellow":
          
          break;
      
        default:
          break;
      }
       
       
    })
.catch((error) => {
        console.error("Error:", error);
        // Handle the error here
      });

}


const sendMessagesToAllConnections = async (userId,color) => {
  const datapath = `users/${userId}/connections`
  const connectionsRef = dbref(database,datapath);
  const phoneNumbers = [];

 await dbOnValue(connectionsRef,(snapshot)=>{
    console.log(snapshot.val())
    const connections = snapshot.val();
    Object.values(connections).forEach((connection)=>{
      console.log(connection)
      if (connection.phonenumber) {
        sendTwilioMessage(connection.phonenumber,helpMessage(color,connection.firstname),'general')
      }
    })

  })


  // return connectionsRef
  //   .once('value')
  //   .then((snapshot) => {
  //     const connections = snapshot.val();
  //     if (connections) {
  //       // Loop through each connection and add the phone number to the phoneNumbers array
  //       Object.values(connections).forEach((connection) => {
  //         if (connection.phonenumber) {
  //           sendTwilioMessage(connection.phonenumber,helpMessage(color),'general')
  //           //phoneNumbers.push(connection.phonenumber);
  //         }
  //       });
  //     }
  //    // return phoneNumbers;
  //   })
  //   .catch((error) => {
  //     console.error('Error retrieving connections:', error);
  //     return [];
  //   });
}

export function createInitials(fname,lname){
  return fname[0] + lname[0]
}
export const fetchUserConnections=()=>{
  getCurrentUserUid()
  .then((uid) => {
  const datapath = dbref(database,'users/' + uid + "/conections")
   dbOnValue(datapath,(snapshot)=>{
    const userData = snapshot.val();
  if (userData) {
    //setConnections(userData.connections);
    console.log(userData)
  } else {
    console.log('User not found');
  }


   })
  

  })
  
};




export const getMyCircle= ()=>{
    getCurrentUserUid()
  .then((uid) => {
    const profileInfoPath = "users/" + uid + "/connections"
    // const profileInfoPath = "users/fWZwWJGDLqUlTtAAuRcAeFhLSZ42"
      var profileInfo = dbref(database,profileInfoPath);
      let profile = {}
      dbOnValue(profileInfo,(profiledata) => {
     
        Object.entries(profiledata.val()).forEach((key, value) => {
            console.log(key, value);
            profile[key] = value;
          });
        
          console.log("Processed Profile:", profile);
      })
     // getMoodOfUser(uid)
     // setMyMood("ORANGE")
     //allUsers()
     // addToMyCircle(uid,'361ooYQieaVkRKiGeREgdD7Feo3v3')
      return profile
  })
  .catch((error) => {
    console.error("Error:", error);
    // Handle the error here
  });
  
}
export const getUserInfo= (uid)=>{
   
    const profileInfoPath = "users/" + uid + "/info"
    // const profileInfoPath = "users/fWZwWJGDLqUlTtAAuRcAeFhLSZ42"
      var profileInfo = dbref(database,profileInfoPath);
      let profile = {}
      dbOnValue(profileInfo,(profiledata) => {
        console.log("Processed Profile:", profiledata.val());
        profile = {
          "email": profiledata.val()['email'],
          "firstname": profiledata.val()['firstname'],
          "lastname": profiledata.val()['lastname'],
          "initials": createInitials(profiledata.val()['firstname'],profiledata.val()['lastname']),
          "mood": profiledata.val()['mood'],
          "phonenumber": profiledata.val()['phonenumber'],
          "uid": uid
        }

       

        // Object.entries(profiledata.val()).map((key, value) => {
        //     console.log(key, value);
        //     profile[key] = value;
        //   });
        
          
      })
     // getMoodOfUser(uid)
     // setMyMood("ORANGE")
     // addToMyCircle(uid,'361ooYQieaVkRKiGeREgdD7Feo33')
      return profile
  
  
}





export const getMyCircle_old = {
    "results": [
        {
            "fullName": "John Doe",
            "mood": "Green",
            "unreadMessages": 5,
            "lastSeenOnline": "2023-07-21T14:30:00Z",
            "initials": "JD"
        },
        {
            "fullName": "Jane Smith",
            "mood": "Black",
            "unreadMessages": 2,
            "lastSeenOnline": "2023-07-21T13:20:00Z",
            "initials": "JS"
        },
        {
            "fullName": "Tom Anderson",
            "mood": "Purple",
            "unreadMessages": 10,
            "lastSeenOnline": "2023-07-21T12:15:00Z",
            "initials": "TA"
        },
        {
            "fullName": "Sara Johnson",
            "mood": "Yellow",
            "unreadMessages": 0,
            "lastSeenOnline": "2023-07-21T11:45:00Z",
            "initials": "SJ"
        },
        {
            "fullName": "Bob Richards",
            "mood": "Green",
            "unreadMessages": 7,
            "lastSeenOnline": "2023-07-21T10:30:00Z",
            "initials": "BR"
        },
        {
            "fullName": "Alice Davis",
            "mood": "Black",
            "unreadMessages": 3,
            "lastSeenOnline": "2023-07-21T09:30:00Z",
            "initials": "AD"
        },
        {
            "fullName": "Charlie Brown",
            "mood": "Purple",
            "unreadMessages": 9,
            "lastSeenOnline": "2023-07-21T08:30:00Z",
            "initials": "CB"
        },
        {
            "fullName": "Nina Martin",
            "mood": "Yellow",
            "unreadMessages": 4,
            "lastSeenOnline": "2023-07-21T07:30:00Z",
            "initials": "NM"
        },
        {
            "fullName": "Robert Wilson",
            "mood": "Green",
            "unreadMessages": 8,
            "lastSeenOnline": "2023-07-21T06:30:00Z",
            "initials": "RW"
        },
        {
            "fullName": "Emily Taylor",
            "mood": "Black",
            "unreadMessages": 1,
            "lastSeenOnline": "2023-07-21T05:30:00Z",
            "initials": "ET"
        },
        {
            "fullName": "David Jones",
            "mood": "Purple",
            "unreadMessages": 5,
            "lastSeenOnline": "2023-07-21T04:30:00Z",
            "initials": "DJ"
        },
        {
            "fullName": "Sophia Thomas",
            "mood": "Yellow",
            "unreadMessages": 0,
            "lastSeenOnline": "2023-07-21T03:30:00Z",
            "initials": "ST"
        },
        {
            "fullName": "James Miller",
            "mood": "Green",
            "unreadMessages": 6,
            "lastSeenOnline": "2023-07-21T02:30:00Z",
            "initials": "JM"
        },
        {
            "fullName": "Emma Williams",
            "mood": "Black",
            "unreadMessages": 2,
            "lastSeenOnline": "2023-07-21T01:30:00Z",
            "initials": "EW"
        },
        {
            "fullName": "Michael Rodriguez",
            "mood": "Purple",
            "unreadMessages": 9,
            "lastSeenOnline": "2023-07-20T23:30:00Z",
            "initials": "MR"
        },
        {
            "fullName": "Olivia Garcia",
            "mood": "Yellow",
            "unreadMessages": 4,
            "lastSeenOnline": "2023-07-20T22:30:00Z",
            "initials": "OG"
        },
        {
            "fullName": "Daniel Martinez",
            "mood": "Green",
            "unreadMessages": 7,
            "lastSeenOnline": "2023-07-20T21:30:00Z",
            "initials": "DM"
        },
        {
            "fullName": "Ava Hernandez",
            "mood": "Black",
            "unreadMessages": 3,
            "lastSeenOnline": "2023-07-20T20:30:00Z",
            "initials": "AH"
        },
        {
            "fullName": "Matthew Lopez",
            "mood": "Purple",
            "unreadMessages": 8,
            "lastSeenOnline": "2023-07-20T19:30:00Z",
            "initials": "ML"
        },
        {
            "fullName": "Mia Gonzalez",
            "mood": "Yellow",
            "unreadMessages": 0,
            "lastSeenOnline": "2023-07-20T18:30:00Z",
            "initials": "MG"
        }
    ]
}

