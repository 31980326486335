import React from 'react';
import PropTypes from 'prop-types';
import './TitleHeader.css';
import { TitleHeaderTitle } from "../../utils/constants";


const TitleHeader = (props) => (
 <>
 <div className='title'>
    {props.title}
  </div>
  <div className='subtitle'>
    {props.subtitle}
  </div>


  </>
);

TitleHeader.propTypes = {};

TitleHeader.defaultProps = {};

export default TitleHeader;
