import logo from './logo.svg';
import './App.css';
import Footer from './components/Footer/Footer';
import * as Constants from './utils/constants';
import CircleList from './components/CircleList/CircleList';
import MasterPage from './components/MasterPage/MasterPage'
import OnboardingMoodPicker from './components/OnboardingMoodPicker/OnboardingMoodPicker';
import { Routes, Route, Link } from "react-router-dom";
import Chat from './components/Chat/Chat';
import SendMessageForm from './components/SendMessage/SendMessage';
import Login from './components/Login/Login';
import SignUp from './components/SignUp/SignUp';
import Connect from './components/Connect/Connect';
import Cares from './components/Cares/Cares';
import Status from './components/Status/Status';
import React, { useState } from 'react';

export function App() {
  
  const isLoggedIn = false; 


  return (
   <>
   
   <Routes>
    <Route path='/' element={<MasterPage child={<OnboardingMoodPicker/>}/>} ></Route>
    <Route path='/mood' element={<MasterPage child={<OnboardingMoodPicker/>}/>} ></Route>
    <Route path='/circles' element={<MasterPage child={<CircleList/>}/>}></Route>
    <Route path='/cares' element={<MasterPage child={<Cares/>}/>}></Route>
    <Route path='/status' element={<MasterPage child={<Status/>}/>}></Route>
    <Route path='/connect' element={<MasterPage child={<Connect/>}/>}></Route>
    <Route exact path="/login" element={<Login/>} />
    <Route exact path="/signup" element={<SignUp/>} />
    <Route path='/chat/:connect' element={<MasterPage child={<Chat/>}/>}></Route>
    <Route path='/message' element={<MasterPage child={<SendMessageForm/>}/>}></Route>
   </Routes>
  
   </>
  );
}

export default App;
