import React, { useState } from 'react';
import {sendTwilioMessage} from '../../utils/api'


function SendMessageForm() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');

  const onSubmit = async (e) => {
    e.preventDefault();

    sendTwilioMessage(phoneNumber,message,'general');
   
  };

  return (
    <form onSubmit={onSubmit}>
      <input 
        type="tel" 
        placeholder="Phone Number" 
        value={phoneNumber} 
        onChange={(e) => setPhoneNumber(e.target.value)}
      />
      <textarea 
        placeholder="Message" 
        value={message} 
        onChange={(e) => setMessage(e.target.value)}
      />
      <button type="submit">Send Message</button>
    </form>
  );
}

export default SendMessageForm;
