import React,{useEffect,useState,useContext} from 'react';
import { List, InputItem, Button, Input } from 'antd-mobile';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import {dbChild,dbref,dbOnValue,firebaseConfig,dbset} from '../../utils/dataobject';
import { useParams,useNavigate } from 'react-router-dom';
import { AppContext } from '../../AppContext'
import { getMyCircle,allUsers,allUsersx,getMyCircle_old, addToMyCircle, currentUserID, markMessagesAsRead, getUserInfo,sendTwilioMessage} from '../../utils/api';
import './Chat.css'
// Firebase Config


firebase.initializeApp(firebaseConfig);
const database = firebase.database();

const Chat = () => {
  const routeParams = useParams();
  const [connect,setConnect] =useState(routeParams.connect)
  const [user, setUser] = useState(null);
  const [messageReceiver, setMessageReceiver] = useState(null);
  const [chatHeader , setChatHeader] = useState('');
  const [receiver, setReceiver] = useState(routeParams.connect);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [loginError, setLoginError] = useState(null);
  const { appUser ,appUserMood,appCurrentReciever } = useContext(AppContext);
  const chatEndRef = React.useRef(null);

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }


  function createInitials(fname,lname){
    return fname[0] + lname[0]
  }
  const getUserInfo = (uid) => {
    return new Promise((resolve) => {
      const profileInfoPath = "users/" + uid + "/info";
      var profileInfo = dbref(database, profileInfoPath);
      dbOnValue(profileInfo, (profiledata) => {
        const profile = {
          "email": profiledata.val()['email'],
          "firstname": profiledata.val()['firstname'],
          "lastname": profiledata.val()['lastname'],
          "initials": createInitials(profiledata.val()['firstname'], profiledata.val()['lastname']),
          "mood": profiledata.val()['mood'],
          "phonenumber": profiledata.val()['phonenumber'],
          "uid": uid
        };
        resolve(profile);
      });
    });
  };
  useEffect(() => {
    // Set up Firebase Auth listener
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        getUserInfo(routeParams.connect).then(recieverInfo => {
          setMessageReceiver(recieverInfo);
          setChatHeader("Chat with " + recieverInfo['firstname']);
        });
      } else {
        setUser(null);
      }
      scrollToBottom()
    });

    return () => unsubscribe(); // Cleanup the listener on unmount
  }, [user,routeParams.connect,messages]);

  // Function to handle user login
  const handleLogin = () => {
    setLoginError(null);
    firebase
      .auth()
      .signInWithEmailAndPassword(loginEmail, loginPassword)
      .catch((error) => {
        setLoginError(error.message);
      });
  };

  // Function to handle user logout
  const handleLogout = () => {
    firebase.auth().signOut();
  };

  const sendMessage = () => {
    if (!message.trim() || !receiver.trim()) return;

    // Save the message in the database
    const messageData = {
      sender: user.uid,
      receiver,
      content: message.trim(),
      "read":false,
      timestamp: firebase.database.ServerValue.TIMESTAMP,
    };

    
    // Use push method to automatically generate a unique key for the message
    database.ref('messages').push(messageData, (error) => {
      if (error) {
        console.error('Error sending message:', error);
      } else {
        setMessage('');
      }
    });

    const connectionPath = `users/` + user.uid + `/connections/` + receiver + "/lastMessage"
    const connectionPath2 = `users/` + receiver + `/connections/` + user.uid + "/lastMessage"
    dbset(dbref(database,connectionPath),firebase.database.ServerValue.TIMESTAMP)
    dbset(dbref(database,connectionPath2),firebase.database.ServerValue.TIMESTAMP)

    sendMessageViaSMS(user, messageReceiver);



  };
  // Function to load messages from the database
  useEffect(() => {
    if (user) {

      markMessagesAsRead(user.uid,appCurrentReciever)

      const messagesRef = database.ref('messages');
      const query = messagesRef.orderByChild('timestamp');

      query.on('value', (snapshot) => {
        const messageList = [];

        snapshot.forEach((childSnapshot) => {
          const message = childSnapshot.val();
          messageList.push(message);
        });
       // messageList.sort((a, b) => b.timestamp - a.timestamp);
        setMessages(messageList);
      });

      return () => query.off(); // Cleanup the listener on unmount
    }
  }, [user]);

  // Filter messages based on the current user and the selected receiver
  const filteredMessages = messages.filter(
    (msg) =>
      (msg.sender === user.uid && msg.receiver === receiver) ||
      (msg.sender === receiver && msg.receiver === user.uid)
  );

  if (!user) {
    return (
      <div style={{ padding: 16 }}>
        <h2>Please log in to use the chat system.</h2>
        <Input
          value={loginEmail}
          onChange={(value) => setLoginEmail(value)}
          placeholder="Email"
        />
        <Input
          type="password"
          value={loginPassword}
          onChange={(value) => setLoginPassword(value)}
          placeholder="Password"
        />
        {loginError && <p style={{ color: 'red' }}>{loginError}</p>}
        <Button onClick={handleLogin}>Log in</Button>
      </div>
    );
  }

  return (
    <div style={{ padding: 16, display: 'flex', flexDirection: 'column', height: '100vh' }}>
    <h2>{chatHeader}</h2>
    {!user ? (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Input
          value={loginEmail}
          onChange={(value) => setLoginEmail(value)}
          placeholder="Email"
          style={{ marginBottom: 8 }}
        />
        <Input
          type="password"
          value={loginPassword}
          onChange={(value) => setLoginPassword(value)}
          placeholder="Password"
          style={{ marginBottom: 8 }}
        />
        {loginError && <p style={{ color: 'red', marginBottom: 8 }}>{loginError}</p>}
        <Button onClick={handleLogin}>Log in</Button>
      </div>
    ) : (
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        {/* <Input
          value={receiver}
          onChange={(value) => setReceiver(value)}
          placeholder="Receiver's UID"
          style={{ marginBottom: 8 }}
        /> */}
        <div style={{ flex: 1, overflowY: 'scroll', marginBottom: 180 }}>
          <List>
          {filteredMessages.map((msg) => (
  <List.Item
    key={msg.id}
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: msg.sender === user.uid ? 'flex-end' : 'flex-start',
    }}
  >
    <div
      style={{
        background: msg.sender === user.uid ? '#1890ff' : '#f0f0f0',
        color: msg.sender === user.uid ? '#fff' : '#000',
        padding: '8px 12px',
        borderRadius: '8px',
        maxWidth: '80%',
      }}
    >
      {msg.content}
    </div>
    <div
      style={{
        fontSize: '0.8em',
        color: '#aaa',
        padding: '4px 12px',
      }}
    >
      {new Date(msg.timestamp).toLocaleString()} {/* Convert timestamp to human-readable format */}
    </div>
  </List.Item>
))}

          </List>
          <div ref={chatEndRef}></div>
        </div>
        <div className='chatdiv'>
          <div className='chat-message-field'>

          <Input
            value={message}
            onChange={(value) => setMessage(value)}
            placeholder="Type your message..."
            style={{ flex:'70%', marginLeft: 8, marginRight: 8 }}
          />
          </div>
          <div className='chat-send-button'>
          <Button onClick={sendMessage}>Send</Button>
      
        </div>
          
          
        </div>
      </div>
    )}
  </div>
  );
};

export default Chat;
function sendMessageViaSMS(user, messageReceiver) {
  const sendChatMessage = "Your friend " + user.displayName + " sent you a message on TakeCare (www.gettakecare.com)";
  //send Message
  sendTwilioMessage(messageReceiver.phonenumber, sendChatMessage, 'general');
}

