import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

export const firebaseConfig = {
    apiKey: "AIzaSyDZ6IZucanE9ySHY3n8IK5ptzYxdz9g_CQ",
    authDomain: "takecare-f1da3.firebaseapp.com",
    projectId: "takecare-f1da3",
    databaseURL:"https://takecare-f1da3-default-rtdb.firebaseio.com/",
    storageBucket: "takecare-f1da3.appspot.com",
    messagingSenderId: "246473179975",
    appId: "1:246473179975:web:361136b07577ca0817c262",
    measurementId: "G-773SBH6TVQ"
  };
  
  // Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;