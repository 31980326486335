//const client = require("twilio");
import React ,{Component,useEffect,useState,useContext}from 'react';
import PropTypes from 'prop-types';
import './Status.css';
import {allMoods,moodPickerContent} from '../../utils/constants';
import {sendTwilioMessage, setMyMood} from '../../utils/api';
import TitleHeader from '../TitleHeader/TitleHeader'
import { Button, Space } from 'antd-mobile';
import { Link, useLoaderData, useNavigation } from 'react-router-dom';
import Login from '../Login/Login';
import SignUp from '../SignUp/SignUp';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { getAuth, onAuthStateChanged } from 'firebase/compat/auth';
import 'firebase/compat/database';
import { firebaseConfig, getCurrentUserUid } from '../../utils/dataobject';
import { getUserInfo, allUsersx, getMyCircle_old, addToMyCircle, currentUserID, getUserConnections } from '../../utils/api';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../AppContext'

firebase.initializeApp(firebaseConfig);
const Status=()=>{
  const { appUser } = useContext(AppContext);
  const { appUserMood, setAppUserMood  } = useContext(AppContext);
  const [users, setUsers] = useState([]);
  const [userDisplayName, setUserDisplayName] = useState('');
  const [user, setUser] = useState('');
  const [userID, setUserID] = useState("");
let [currentMood,setMood] = useState('')
const handleLogout = () => {
  firebase.auth().signOut();
  // User has logged out
};

let navigate = useNavigate()
//const user = firebase.auth().currentUser;
const selectMood=async (e)=>{
    var mood = e.currentTarget.getAttribute("data-value");
    var moodMessage = e.currentTarget.getAttribute("data-message");
    var moodLabel = document.getElementById('moodlabel')
    
    moodLabel.hidden = false
    await setAppUserMood(mood)
    setMood(mood);
    setMyMood(mood);
   
    //navigate('/circles')
    
 
  }

const setCurrentMood=()=>{
  if (!appUserMood){

  } else {
    
    return(
<div>
  Your current mood : {appUserMood}
</div>


    )
  }
}
  useEffect(() => {
    // Set up Firebase Auth listener
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        const firstName = getUserInfo(user.uid)['firstname']
        setUserDisplayName(firstName)
        
         // Store the user's authentication state in localStorage
      localStorage.setItem('user', JSON.stringify(user));
      } else {
        setUser(null);
        setUserDisplayName('')
        localStorage.removeItem('user');
      }
    });

   
      // Try to restore the user's authentication state from localStorage
      const storedUser = JSON.parse(localStorage.getItem('user'));
      if (storedUser) {
        setUser(storedUser);
      }

    const fetchUserID = async () => {
      try {
        const id = await getCurrentUserUid();
            setUserID(id.displayName);
      } catch (error) {
        console.error(error)
      }
    }

   
   
    fetchUserID();
   

    return () => unsubscribe(); // Cleanup the listener on unmount
  }, []);

return( 

<>

<div>
      
      {user ? (
        
        <div className="OnboardingMoodPicker">
        
       
        <div>{user.identifier}</div>
        <div className='moodholder'>
        {
        allMoods.map(mood =>(
           
           <div className='mooditem' key={mood.mood} >
           <Button className={mood.style} data-value={mood.mood} data-message={mood.moodMessage} onClick={selectMood} >
           {mood.mood}
           </Button>
           <div className='mood-caption'>{mood.message}</div>
           </div>
     
        ))
       }
     
     
     <div id='moodlabel' hidden='true' style={{fontSize:'large'}}>
       Your current mood : {currentMood}
     </div>
     
     {/* <Link to="/circles" className='mood-letsgetstarted'>Let's Get Started</Link> */}
       
     </div>
     
     
     
       </div>



      ) : (
        <>
          <Login />
        
        </>
      )}
    </div>


  
  
 
  
  
  </>
  
  
  )
};

Status.propTypes = {};

Status.defaultProps = {};

export default Status;
