import React from 'react';
import PropTypes from 'prop-types';
import './Footer.css';
//import {iconx} from '../../images/icon.png' 
import * as Constants from '../../utils/constants';
import { Badge, TabBar,Image } from 'antd-mobile';
import {
  AppOutline,
  MessageOutline,
  MessageFill,
  UnorderedListOutline,
  UserOutline,
} from 'antd-mobile-icons';

export const menuTabs  = [
  {
    key: '/home',
    title: 'Cares',
    icon: <AppOutline />,
  },
  {
    key: '/circles',
    title: 'Circle',
    icon: <AppOutline />,
  },
  {
    key: '/message',
    title: 'Add',
    icon:<AppOutline />,
  },
  {
    key: '/me',
    title: 'Status',
    icon: <UserOutline />,
  },
]

const Footer = () => (
  <div className="Footer">
  {/* <TabBar>
          {menuTabs.map(item => (
            <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
          ))}
        </TabBar> */}

<footer className="footer-container">
      <div className="footer-icons">
        {/* Replace the URLs with your icon URLs or icon components */}
        <a href="/cares">
        <Image alt='cares' height={40} width={40} src={require('../../images/chat.png')}/>
        Chats
        </a>
        <a href="/circles">
        <Image alt='circles' height={40} width={40}  src={require('../../images/circle.png')}/>
        Circle
        </a>
        <a href="/connect">
        <Image alt='connect' height={40} width={40}  src={require('../../images/add.png')}/>
        Add
        </a>
        <a href="/status">
        <Image alt='status' height={40} width={40} src={require('../../images/status.png')}/>
        Status
        </a>
      </div>
    </footer>
  </div>
);

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
