import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link, useLoaderData, useNavigation } from 'react-router-dom';
import { Button, List, Space } from 'antd-mobile';
import './CircleList.css';
import { getUserInfo, allUsersx, getMyCircle_old, addToMyCircle, currentUserID, getUnreadMessageCountBetweenUsers, fetchUserConnections,allUsersConnections } from '../../utils/api';
import CircleItem from '../CircleItem/CircleItem';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { getAuth, onAuthStateChanged } from 'firebase/compat/auth';
import 'firebase/compat/database';
import { firebaseConfig, getCurrentUserUid } from '../../utils/dataobject';
import { useParams, useNavigate } from 'react-router-dom';
import TitleHeader from '../TitleHeader/TitleHeader';
import { AppContext } from '../../AppContext'
import MoodBar from '../MoodBar/MoodBar';

// Firebase Config
firebase.initializeApp(firebaseConfig);

const CircleList = () => {
  const { appUser, appUserMood } = useContext(AppContext);
  const { appCurrentReciever, setAppCurrentReciever } = useContext(AppContext);
  const [users, setUsers] = useState([]);
  const [userDisplayName, setUserDisplayName] = useState('');
  const [user, setUser] = useState('');
  const [userx, setUserx] = useState('');
  const navigate = useNavigate();
  const [userID, setUserID] = useState(""); 
  const welcomeTitle = "Hello, " + user.displayName;
  allUsersConnections()


  function connectWithUser(e) {
    let newConnection = e.currentTarget.getAttribute('data-value');
    setAppCurrentReciever(newConnection)
    navigate('/chat/' + newConnection);
  }


  useEffect(() => {
    // Set up Firebase Auth listener
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        setUserx(user);
        const firstName = getUserInfo(user.uid)['firstname']
        setUserDisplayName(firstName)
        
         // Store the user's authentication state in localStorage
      localStorage.setItem('user', JSON.stringify(user));
      } else {
        setUser(null);
        setUserx(null);
        setUserDisplayName('')
        localStorage.removeItem('user');
      }
    });

      // Try to restore the user's authentication state from localStorage
      const storedUser = JSON.parse(localStorage.getItem('user'));
      if (storedUser) {
        setUserx(storedUser);
        setUser(storedUser);
      }

    const fetchUserID = async () => {
      try {
        const id = await getCurrentUserUid();
        setUserID(id.displayName);
      } catch (error) {
        console.error(error)
      }
    }

    const fetchUsers = async () => {
      setUsers([]);
      try {
        const usersData = await allUsersConnections();
        //const usersData = await getUserConnections(appUser.uid);
        console.log("THIS IS USERSDATA[1] ::" + usersData[1])
        setUsers(usersData);
      } catch (error) {
        console.error(error);
      }
    };
    
    fetchUserID();
    fetchUsers();
   
    

    return () => unsubscribe(); // Cleanup the listener on unmount
  }, []);

  return (
    <>
      <TitleHeader title={welcomeTitle} />
     

<MoodBar/>

      <div className="CircleList">
        <h2>My Circle</h2>
        <>
          {users.map((user, index) => (
            <CircleItem person={user} loggedinuser ={userx} showcolor={true} showunreadmessages={true} showcheckin={true} uid={user['uid']} connect={true} data-value={user['uid']} index={index}  showunread={true} />
          ))}
        </>
      </div>
    </>
  );
};

CircleList.propTypes = {};

CircleList.defaultProps = {};

export default CircleList;
