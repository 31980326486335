import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import './SignUp.css'
import 'firebase/compat/auth';
import { useNavigate } from 'react-router-dom';
const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate()

  const handleSignUp = async () => {
    try {
      await firebase.auth().createUserWithEmailAndPassword(email, password);

      // Update user profile with additional data
      const currentUser = firebase.auth().currentUser;
      if (currentUser) {
        await currentUser.updateProfile({
          displayName: `${firstName} ${lastName}`,
          phoneNumber: phoneNumber
        });
        console.log(currentUser.email)

        await createFirebaseUserDetails(firstName, lastName, phoneNumber, email, currentUser);
        
      navigate('/login')
      }

      
   

      // You can save additional user data to the database if needed
      // For example, using Firebase Realtime Database or Firestore

      // User has successfully signed up
    } catch (error) {
      setError(error.message);
    }
  };

  return (
<div id="signup" className="signup-container">
      <h2>Sign Up</h2>
      <input
        type="text"
        placeholder="First Name"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
      />
      <input
        type="text"
        placeholder="Last Name"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
      />
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <input
        type="tel"
        placeholder="Phone Number"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
      />
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <button onClick={handleSignUp}>Sign Up</button>
    </div>
  );
};

export default SignUp;

function createInitials(fname,lname){
  return fname[0] + lname[0]
}
async function createFirebaseUserDetails(firstName, lastName, phoneNumber, email, currentUser) {
  const initials = createInitials(firstName,lastName)
  const userDetails = {
    "firstname": firstName,
    "lastname": lastName,
    "initials" : initials,
    "phonenumber": phoneNumber,
    "email": email,
    "mood"  : "Green"
  };
  await firebase.database().ref(`users/${currentUser.uid}/info`).set(userDetails);
}

