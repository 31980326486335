export const appName = "TakeCare"


//Landing





// My Circle 


export const pageTitles = {
    "circles":"My Circles",
    "home":"", 
    "welcome": "Welcome to  TakeCare..."
}

export const TitleHeaderTitle = "Hi , Welcome to TakeCare"

export const moodPickerContent = {
    "title" : "Hi , Welcome to TakeCare",
    "subtitle" : "Please select a mood that you are surfacing.",
    "letsgetstartedbutton" : "Let's Get Started"
}

export const twilioConfig = {
  "sendMessageURL": "https://takecare-welcome-9356.twil.io/sendmessage",
  "welcomeMessageURL": "https://takecare-welcome-9356.twil.io/welcome"
}


export const helpMessage =(color,friend)=>{

  switch (color) {
   
    case 'purple':

    return "TakeCare: Your friend " + friend + " can really use some support right now, because their mood color has changed to " + color + ". Send them a a support message  https://takecare-f1da3.web.app/ " 
      
      break;

    case 'dark':
      return "TakeCare: Your friend " + friend + " can really use some support right now, because their mood color has changed to " + color + ". Send them a a support message  https://takecare-f1da3.web.app/ " 
      break;
  
    default:
      break;
  }

}

// Green: #048c10
// Purple: #61079c
// Yellow: #Ffe700
// Dark: #000000

export const allMoods = [
    {
      "mood": "Green",
      "message": "Feeling Good",
      "moodMessage": "'s mood has changed.",
      "style": "mood-selectorbutton-Green",
    },
     {
      "mood": "Purple",
      "message": "I could really use support",
      "moodMessage": "'s mood has changed to Purple. Help him out",
      "style": "mood-selectorbutton-Purple",
    },
    {
      "mood": "Yellow",
      "message": "I'm okay, just stressed",
      "moodMessage": "'s mood has changed.",
      "style": "mood-selectorbutton-Yellow",
    },
 
    {
      "mood": "Dark",
      "message": "I'm in bad shape",
      "moodMessage": "'s mood has changed to Black. Help him out",
      "style": "mood-selectorbutton-Dark",
     
    }
  ]

