import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { List,Tag,Space, Button } from 'antd-mobile';
import './Connected.css';
import { getCurrentUserUid, dbref, dbOnValue, database } from '../../utils/dataobject';

const Connected = ( props ) => {
  const [connected, setConnected] = useState(false); // State to track connection status

  useEffect(() => {
    const isUserConnectedToMe = (connectionUID) => {
      getCurrentUserUid().then((uid) => {
        const connectorConnectionsPath = `users/${uid}/connections/${connectionUID}`;
        const datapath = dbref(database, connectorConnectionsPath);
        dbOnValue(datapath, (snapshot) => {
          setConnected(snapshot.exists()); // Set connected based on whether the snapshot exists or not
        });
      });
    };

    // Update the connected state when the person.uid changes
    isUserConnectedToMe(props.uid);
  }, [props.uid]); // Dependency array to re-run the effect when the person.uid changes

  return (
    <div className="Connected">
      {connected ? <div data-connected='true'><Tag color='gray'>Connected</Tag></div> : <div data-connected='true'><Tag color='blue'>Connect</Tag></div>}
    </div>
  );
};



export default Connected;
