import React from 'react';
import PropTypes from 'prop-types';
import { Layout, Space,Image } from 'antd-mobile';
import './MasterPage.css';
import  '../../images/logo.png' 
import Footer from '../Footer/Footer';

//const { Header, Footer, Sider, Content } = Layout;

const MasterPage = ({child}) => (
//   <div className="MasterPage">
//    {child}
//    {/* <div className='footer-pos'>
   
    
//    </div> */}
//  <Footer/>
//   </div>

<div className="MasterPage">
    
<div className='header-container'>
   <div>
    <Image className='logo-img' alt='logo' height={80} width={80} src={require('../../images/logo.png')}/>
   </div>
    <div>
        <h1>TakeCare</h1>
    </div>

</div>


<div className="main-content">
{child}
</div>
{/* Footer component */}
<Footer />
</div>
  
);

MasterPage.propTypes = {};

MasterPage.defaultProps = {};

export default MasterPage;
