import React, { useEffect,useState } from 'react';
import PropTypes from 'prop-types';
import { dbref,dbOnValue,database } from '../../utils/dataobject';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import './MoodBar.css';
//import { getMoodOfUser } from '../../utils/api';



 const colorReturn=(color)=>{
    switch (color) {
      case "Green": 
        return <div className='colorbubble-green-large'> 
        </div>
        break;
      case "Dark":
        return <div className='colorbubble-black-large'> 
        </div>
        break;
      case "Purple":
        return <div className='colorbubble-purple-large'> 
        </div>
        
        break;
      case "Yellow":
        return <div className='colorbubble-yellow-large'> 
        </div>
        
        break;
    
      default:
        break;
    }
  
  }


const MoodBar = () => {
  const [theMood,setTheMood]= useState('')

 

   useEffect(() => {
    // Set up Firebase Auth listener
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        

        const getMoodOfUser=(uid)=> {
          const datapath = `users/` + uid + `/info/mood`
          var profileInfo = dbref(database,datapath);
          let mood = ""
          dbOnValue(profileInfo,(profiledata) => {
                mood = profiledata.val()
                setTheMood(mood)
            })
         
            //return mood
          }
   
getMoodOfUser(user.uid)

      };
   


    return () => unsubscribe(); // Cleanup the listener on unmount
  })}, []);



  return(
    <div className='mood-row'>
    <div>
My current mood:
    </div>
    <div>
    {theMood}
    </div>
     <div>
     {colorReturn(theMood)}
    </div> 
    </div>
  )
  };

MoodBar.propTypes = {};

MoodBar.defaultProps = {};

export default MoodBar;
