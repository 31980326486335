import React, { useEffect, useState, useContext } from 'react';
import './Connect.css';
import { getCurrentUserUid } from '../../utils/dataobject';
import { allUsersx, addToMyCircle } from '../../utils/api';
import { useParams, useNavigate } from 'react-router-dom';
import CircleItem from '../CircleItem/CircleItem';
import { Button } from 'antd-mobile';

const Connect = () => {
  const [users, setUsers] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [userID, setUserID] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);

    if (value.trim() === '' || value.length < 3) {
      // Clear the search results if the search query is empty or less than 3 characters
      setUsers([]);
    }
  };

  const handleSearch = () => {
    if (searchQuery.trim() === '' || searchQuery.length < 3) {
      // Do not perform the search if the search query is empty or less than 3 characters
      return;
    }

    const filteredUsers = Object.values(usersData).filter((user) => {
      const { firstname, lastname, phonenumber } = user.info;
      const connections = user.connections
      return (
        firstname.toLowerCase().includes(searchQuery.toLowerCase()) ||
        lastname.toLowerCase().includes(searchQuery.toLowerCase()) ||
        phonenumber.includes(searchQuery)
      );
    });

    setUsers(filteredUsers);
  };

  function connectWithUser(e) {
    let newConnection = e.currentTarget.getAttribute('data-value');
    let isConnected = e.currentTarget.getAttribute('data-connected');
    if (isConnected!=true){
    addToMyCircle(newConnection);
      navigate('/circles/');
    }
   
  }

  useEffect(() => {
    const fetchUserID = async () => {
      try {
        const id = await getCurrentUserUid();
        setUserID(id.displayName);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchUsers = async () => {
      try {
        const allUsersData = await allUsersx();
        setUsersData(allUsersData);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUserID();
    fetchUsers();
  }, []);

  return (
    <div className="connect-container">
      <h2>New Connection Search</h2>
      <input
        type="text"
        placeholder="Search by first or last name."
        value={searchQuery}
        onChange={handleChange}
      />
      <Button onClick={handleSearch}>Search</Button>
      {searchQuery.length >= 3 && (
        <div className="search-results">
          {users.map((user, index) => (
            <CircleItem
              person={user['info']}
              connections={user['connections']}
              showconnect={true}
              uid={user['uid']}
              connect={true}
              data-value={user['uid']}
              index={index}
              loggedinuser={userID}
              click={connectWithUser}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Connect;
